import React from 'react'
import './Header.css'


const Header=() =>{
    return (
      <div className="Header">
        <img
          src="https://seeklogo.com/images/C/cat-logo-E6BC30BEB2-seeklogo.com.png"
          alt=""
          width="300px"
          color="#387dac"
        ></img>
      </div>
    );
}



export default Header

