import React from "react";
import "./Nav.css";

const Nav = () => {
  return (
    <div className="nav">
      <p>PET WORLD</p>
    </div>
  );
};

export default Nav;
